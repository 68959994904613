export const sequenceList = [
  "Forecast",
  "Field_Batch_Signoff",
  "Start_Spooling",
  "Spooling_Complete",
  "Production_Planning_Complete",
  "Production_Complete",
  "Scheduled_Delivery",
  "Delivered",
];

export const shopList = {
  H: "Henderson Pipe",
  P: "Xanthia Plumbing",
  D: "Xanthia Sheet Metal",
  V: "Salt Lake City Plumbing",
  W: "Salt Lake City Sheet Metal",
};
