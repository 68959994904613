import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse, Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import { getJobListVista } from "./../services";
import { useEnv } from "./../helper";
import { connect } from "react-redux";

const getFeatures = process.env.REACT_APP_FEATURES;
const getAdminFeatures = process.env.REACT_APP_ADMIN_FEATURES;
const arrayFeatures = getFeatures?.split(", ");
const arrayAdminFeatures = getAdminFeatures?.split(", ");

const env = useEnv();
class Sidebar extends Component {
  // constructor(props) {
  //   super(props);

  // this.handleSubmit = this.handleSubmit.bind(this);
  // }

  state = {
    jobOptions: [],
    isJobLoading: false,
    featureList: [],
  };

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  // componentDidMount = () => {
  //   const getFeatures = process.env.REACT_APP_FEATURES;
  //   const arrayFeatures = getFeatures?.split(", ");

  //   this.setState({
  //     featureList: arrayFeatures,
  //   });
  // };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/batch-tracker", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/editors", state: "editorsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  handleChangeJob = (selected) => {
    if (selected) {
      const toSummary = {
        jobName: selected.label,
        jobId: selected.value,
      };
      const toSummaryURL = new URLSearchParams(toSummary).toString();
      window.location = `/field-summary?${toSummaryURL}`;
    }
  };

  _fetchJobOptions = async (jobId) => {
    try {
      const res = await getJobListVista(jobId);
      const jobOptions = res?.data?.map((val) => ({
        label: `${val.vista_id} ${val.description}`,
        value: val.vista_id,
      }));

      return jobOptions;
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { featureList } = this.state;
    const { history, userData } = this.props;
    return (
      <nav className="sidebar sidebar-fixed sidebar-offcanvas" id="sidebar">
        {/* <p
          className="logo-text"
          style={{
            textAlign: "center",
            color: "white",
            fontWeight: "500",
            fontSize: "16px",
          }}
        >
          Connect
        </p> */}
        <ul className="nav">
          <li
            className={
              this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
            }
          >
            {/* <Link className="nav-link" to="/dashboard"> */}
            {/* <i className="ti-home menu-icon"></i>
              <span className="menu-title">Dashboard</span> */}
            <Form noValidate onSubmit={this.handleSubmit} className="pt-3">
              {/* <Form.Group>
                <Form.Control
                  type="text"
                  className="form-control"
                  name="job"
                  id="jobInput"
                  placeholder="Enter Project Number"
                />
              </Form.Group> */}
              <AsyncSelect
                isClearable
                placeholder="Enter Job #"
                loadOptions={debounce(this._fetchJobOptions, 250, {
                  leading: true,
                })}
                onChange={this.handleChangeJob}
                classNamePrefix="job-select"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
              />
            </Form>
            {/* </Link> */}
          </li>
          {/* <li className={ this.isPathActive('/widgets') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/widgets">
              <i className="ti-settings menu-icon"></i>
              <span className="menu-title">Widgets</span>
            </Link>
          </li> */}
          <li className="nav-item" style={{ marginTop: 18 }}>
            {/* <div className="nav-link justify-content-center">
              <span className="menu-title">Applications</span>
            </div> */}
          </li>
          {arrayFeatures?.includes("Batch Tracker") && (
            <li
              className={
                this.isPathActive("/batch-tracker")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className={
                  this.state.appsMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => {
                  this.toggleMenuState("appsMenuOpen");
                  history.push("/batch-tracker/app");
                }}
                data-toggle="collapse"
              >
                <i className="ti-layout menu-icon"></i>
                <span className="menu-title">Batch Tracker</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.appsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/batch-tracker/app")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/batch-tracker/app"
                    >
                      App
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/batch-tracker/csv-export")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/batch-tracker/csv-export"
                    >
                      CSV Export
                    </Link>
                  </li>
                  {/* <li className="nav-item"> <Link className={ this.isPathActive('/apps/kanban-board') ? 'nav-link active' : 'nav-link' } to="/apps/kanban-board">Kanban Board</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/apps/todo-list') ? 'nav-link active' : 'nav-link' } to="/apps/todo-list">Todo List</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/apps/chats') ? 'nav-link active' : 'nav-link' } to="/apps/chats">Chats</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/apps/email') ? 'nav-link active' : 'nav-link' } to="/apps/email">E-mail</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/apps/calendar') ? 'nav-link active' : 'nav-link' } to="/apps/calendar">Calendar</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/apps/gallery') ? 'nav-link active' : 'nav-link' } to="/apps/gallery">Gallery</Link></li> */}
                </ul>
              </Collapse>
            </li>
          )}
          {arrayFeatures?.includes("Field Catalog") && (
            <li
              className={
                this.isPathActive("/catalog") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.catalogApp ? "nav-link menu-expanded" : "nav-link"
                }
                onClick={() => {
                  this.toggleMenuState("catalogApp");
                  history.push("/catalog/requisition");
                }}
                data-toggle="collapse"
              >
                <i className="ti-book menu-icon"></i>
                <span className="menu-title">Job Catalog</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.catalogApp}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/catalog/requisition")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/catalog/requisition"
                    >
                      Material Requisitions
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/catalog/material")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/catalog/material"
                    >
                      Search Products
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
          )}
          {arrayFeatures?.includes("Total Station Scheduler") && (
            <li
              className={
                this.isPathActive("/total-station-scheduler")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className={
                  this.state.total_station
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => {
                  this.toggleMenuState("total_station");
                  history.push("/total-station-scheduler/app");
                }}
              >
                <i className="ti-time menu-icon"></i>
                <span className="menu-title">
                  <Link className="menu-title" to="/total-station-scheduler">
                    Total Station
                  </Link>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.total_station}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/total-station-scheduler/app")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/total-station-scheduler/app"
                    >
                      Total Station List
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/total-station-scheduler/calendar")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/total-station-scheduler/calendar"
                    >
                      Calendar View
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/total-station-scheduler/reschedule")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/total-station-scheduler/reschedule"
                    >
                      Reschedule Appointment
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive(
                          "/total-station-scheduler/summary-report"
                        )
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/total-station-scheduler/summary-report"
                    >
                      Summary Report
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
          )}
          {/* <li className={ this.isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('basicUiMenuOpen') } data-toggle="collapse">
              <i className="ti-palette menu-icon"></i>
              <span className="menu-title">UI Elements</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.basicUiMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/accordions') ? 'nav-link active' : 'nav-link' } to="/basic-ui/accordions">Accordions</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link' } to="/basic-ui/buttons">Buttons</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/badges') ? 'nav-link active' : 'nav-link' } to="/basic-ui/badges">Badges</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/breadcrumbs') ? 'nav-link active' : 'nav-link' } to="/basic-ui/breadcrumbs">Breadcrumbs</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link' } to="/basic-ui/dropdowns">Dropdowns</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/modals') ? 'nav-link active' : 'nav-link' } to="/basic-ui/modals">Modals</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/progressbar') ? 'nav-link active' : 'nav-link' } to="/basic-ui/progressbar">Progress bar</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/pagination') ? 'nav-link active' : 'nav-link' } to="/basic-ui/pagination">Pagination</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/tabs') ? 'nav-link active' : 'nav-link' } to="/basic-ui/tabs">Tabs</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/typography') ? 'nav-link active' : 'nav-link' } to="/basic-ui/typography">Typography</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/tooltips') ? 'nav-link active' : 'nav-link' } to="/basic-ui/tooltips">Tooltips</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/popups') ? 'nav-link active' : 'nav-link' } to="/basic-ui/popups">Popups</Link></li>
              </ul>
            </Collapse>
          </li> */}
          {arrayFeatures?.includes("Production Staging") && (
            <li
              className={
                this.isPathActive("/staging") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.staging ? "nav-link menu-expanded" : "nav-link"
                }
                onClick={() => {
                  this.toggleMenuState("staging");
                  history.push("/staging/app");
                }}
                data-toggle="collapse"
              >
                <i className="ti-folder menu-icon"></i>
                <span className="menu-title">Production Staging</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.staging}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/staging/app")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/staging/app"
                    >
                      App
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/staging/summary")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/staging/summary"
                    >
                      Summary Report
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/staging/csv-export")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/staging/csv-export"
                    >
                      CSV Export
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
          )}

          {arrayFeatures?.includes("Receiving") && (
            <li
              className={
                this.isPathActive("/receiving") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.receiving ? "nav-link menu-expanded" : "nav-link"
                }
                onClick={() => {
                  this.toggleMenuState("receiving");
                  history.push("/receiving/app");
                }}
                data-toggle="collapse"
              >
                <i className="ti-package menu-icon"></i>
                <span className="menu-title">Receiving</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.receiving}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/receiving/app")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/receiving/app"
                    >
                      App
                    </Link>
                  </li>
                  {/* {arrayFeatures?.includes("Backorder Report") && (
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/receiving/backorder-report")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/receiving/backorder-report"
                      >
                        Backorder Report
                      </Link>
                    </li>
                  )} */}
                  {arrayFeatures?.includes("OTIF Report") && (
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive("/receiving/otif-report")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/receiving/otif-report"
                      >
                        OTIF Report
                      </Link>
                    </li>
                  )}
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/receiving/csv-export")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/receiving/csv-export"
                    >
                      Receiving Report
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/receiving/receiving-for-production-planning")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/receiving/receiving-for-production-planning"
                    >
                       Receiving For <br/> Production Planning
                    </Link>
                  </li>  */}
                </ul>
              </Collapse>
            </li>
          )}
          {arrayFeatures?.includes("Task Scheduler") &&
            (userData?.user?.is_superuser ||
              userData?.user?.job_title?.includes("VDC")) && (
              <li
                className={
                  this.isPathActive("/task-scheduler/list")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <div
                  className={
                    this.state.task_scheduler
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => {
                    this.toggleMenuState("task_scheduler");
                    history.push("/task-scheduler/list");
                  }}
                >
                  <i className="ti-calendar menu-icon"></i>
                  <span className="menu-title">
                    <Link className="menu-title" to="/task-scheduler/create">
                      VDC Task Scheduler
                    </Link>
                  </span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={this.state.task_scheduler}>
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/task-scheduler/list")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/task-scheduler/list"
                      >
                        Task List
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/task-scheduler/calendar")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/task-scheduler/calendar"
                      >
                        Calendar View
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/task-scheduler/list")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/task-scheduler/create"
                    >
                      Calendar View
                    </Link>
                  </li> */}
                  </ul>
                </Collapse>
              </li>
            )}
          
          {arrayFeatures?.includes("Spooling Approval") && (
            <li
              className={
                this.isPathActive("/spooling") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.spooling ? "nav-link menu-expanded" : "nav-link"
                }
                onClick={() => {
                  this.toggleMenuState("spooling");
                  history.push("/spooling/app");
                }}
              >
                <i className="ti-receipt menu-icon"></i>
                <span className="menu-title">
                  <Link className="menu-title" to="/spooling">
                    VDC Spooling <div style={{ height: '10px' }} /> Approval
                  </Link>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.spooling}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/spooling/app")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/spooling/app"
                    >
                      App
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/spooling/summary")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/spooling/summary"
                    >
                      Summary Report
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
          )}
          {arrayFeatures?.includes("Batch Plan") && (
            <li
              className={
                this.isPathActive("/batch-plan")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className={
                  this.state.batchPlan ? "nav-link menu-expanded" : "nav-link"
                }
                onClick={() => {
                  this.toggleMenuState("batch-plan");
                  history.push("/batch-plan/app");
                }}
              >
                <i className="ti-layers-alt menu-icon"></i>
                <span className="menu-title">
                  <Link className="menu-title" to="/batch-plan/app">
                    VDC Batch Approval
                  </Link>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.batchPlan}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/batch-plan/app")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/batch-plan/app"
                    >
                      App
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/spooling/summary")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/spooling/summary"
                    >
                      Summary Report
                    </Link>
                  </li> */}
                </ul>
              </Collapse>
            </li>
          )}
          {arrayFeatures?.includes("Tool Shop") && (
            <li className="nav-item">
              <div className="nav-link">
                <i className="ti-ruler-pencil menu-icon"></i>
                <span className="menu-title">Tool Shop</span>
                <i className="menu-arrow"></i>
              </div>
            </li>
          )}
          {arrayFeatures?.includes("Purchasing") && (
            <li className="nav-item">
              <div className="nav-link">
                <i className="ti-shopping-cart menu-icon"></i>
                <span className="menu-title">Puchasing</span>
                <i className="menu-arrow"></i>
              </div>
            </li>
          )}
          {/* {arrayFeatures?.includes("Station Scheduler") && (
            <li
              className={
                this.isPathActive("/station-scheduler") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className="nav-link"
                onClick={() => history.push("/station-scheduler")}
              >
                <i className="ti-package menu-icon"></i>
                <span className="menu-title">
                  <Link className="menu-title" to="/station-scheduler">
                    Total Station Scheduler
                  </Link>
                </span>
                <i className="menu-arrow"></i>
              </div>
            </li>
          )} */}
          {arrayFeatures?.includes("Batch Scheduler") && (
            <li
              className={
                this.isPathActive("/batch-scheduler")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className="nav-link"
                onClick={() => history.push("/batch-scheduler")}
              >
                <i className="ti-truck menu-icon"></i>
                <span className="menu-title">
                  <Link className="menu-title" to="/batch-scheduler">
                    Batch Delivery Scheduler
                  </Link>
                </span>
                <i className="menu-arrow"></i>
              </div>
            </li>
          )}

          {arrayFeatures?.includes("Material Catalog") && (
            <li
              className={
                this.isPathActive("/material-catalog")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className="nav-link"
                onClick={() => history.push("/material-catalog")}
              >
                <i className="ti-agenda menu-icon"></i>
                <span className="menu-title">
                  <Link className="menu-title" to="/material-catalog">
                    Material Catalog
                  </Link>
                </span>
                <i className="menu-arrow"></i>
              </div>
            </li>
          )}
          {arrayFeatures?.includes("Readers") && (
            <li
              className={
                this.isPathActive("/readers") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className="nav-link"
                onClick={() => history.push("/readers/")}
              >
                <i className="ti-package menu-icon"></i>
                <span className="menu-title">
                  <Link className="menu-title" to="/readers">
                    Readers
                  </Link>
                </span>
                <i className="menu-arrow"></i>
              </div>
            </li>
          )}
          {userData?.user?.is_superuser && (
            <>
              {arrayFeatures?.includes("Admin") && (
                <li
                  className={
                    this.isPathActive("/admin") ? "nav-item active" : "nav-item"
                  }
                >
                  <div
                    className={
                      (this.state.admin || this.state?.vdc || this.state?.supplyChain) ? "nav-link menu-expanded" : "nav-link"
                    }
                    onClick={() => {
                      this.toggleMenuState("admin");
                    }}
                  >
                    <i className="ti-vector menu-icon"></i>
                    <span className="menu-title">
                      <span className="menu-title" >
                        Admin
                      </span>
                    </span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={(this.state.admin || this.state?.vdc || this.state?.supplyChain)}>
                    <ul className="nav flex-column sub-menu">
                      {userData?.user?.is_superuser && (
                        <>
                          {arrayAdminFeatures?.includes("Total Station Update Requested By") && (
                            <li
                              className={
                                this.isPathActive("/admin/total-station") ? "nav-item active" : "nav-item"
                              }
                            >
                              <div
                                className={
                                  "nav-link"
                                }
                                onClick={() => {
                                  this.toggleMenuState("vdc");
                                }}
                              >
                                <span className="menu-title">
                                  <Link className="menu-title" >
                                    VDC
                                  </Link>
                                </span>
                                <i className="menu-arrow"></i>
                              </div>
                              <Collapse in={this.state.vdc}>
                                <ul className="nav flex-column sub-menu" style={{ marginLeft: '-30px' }}>
                                  {" "}
                                  {arrayAdminFeatures?.includes(
                                    "Total Station Update Requested By"
                                  ) && (
                                      <li className="nav-item">
                                        {" "}
                                        <Link
                                          className={
                                            this.isPathActive("/admin/total-station")
                                              ? "nav-link active"
                                              : "nav-link"
                                          }
                                          to="/admin/total-station"
                                        >
                                          Total Station Update <br /> Requested By
                                        </Link>
                                      </li>
                                    )}
                                </ul>
                              </Collapse>
                            </li>
                          )}
                        </>
                      )}
                      {arrayAdminFeatures?.includes("Material Records") && (
                        <>
                          <li
                            className={
                              // this.isPathActive("/admin/material-import-log") ? "nav-item active" : "nav-item"
                              this.props.location.pathname?.includes('/admin/material') ? "nav-item active" : "nav-item"
                            }
                          >
                            <div
                              className={
                                "nav-link"
                              }
                              onClick={() => {
                                this.toggleMenuState("supplyChain");
                                // history.push("/admin/material-import-log");
                              }}
                            >
                              <span className="menu-title">
                                <a className="menu-title">
                                  Supply Chain
                                </a>
                              </span>
                              <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={this.state.supplyChain}>
                              <ul className="nav flex-column sub-menu" style={{ marginLeft: '-30px' }}>
                                <li className="nav-item">
                                  {" "}
                                  <Link
                                    className={
                                      this.isPathActive("/admin/material-import-log")
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    to="/admin/material-import-log"
                                  >
                                    Material Record <br /> Import Log
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  {" "}
                                  <Link
                                    className={
                                      this.isPathActive("/admin/material-records")
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    to="/admin/material-records"
                                  >
                                    Material Record <br /> Manager
                                  </Link>
                                </li>
                              </ul>
                            </Collapse>
                          </li>
                        </>
                      )}
                      {arrayAdminFeatures?.includes("Activity Dashboard") && (
                        <li
                          className={
                            this.isPathActive("/admin/activity-dashboard") ? "nav-item active" : "nav-item"
                          }
                        >
                          <div
                            className="nav-link"
                            onClick={() => history.push("/admin/activity-dashboard")}
                          >
                            {/* <i className="ti-archive menu-icon"></i> */}
                            <span className="menu-title">
                              <Link className="menu-title" to="/admin/total-station">
                                Activity Dashboard
                              </Link>
                            </span>
                            <i className="menu-arrow"></i>
                          </div>
                        </li>
                      )}
                      {arrayAdminFeatures?.includes("Job Summary") && (
                        <li
                          className={
                            this.isPathActive("/admin/summary") ? "nav-item active" : "nav-item"
                          }
                        >
                          <div
                            className="nav-link"
                            onClick={() => history.push("/admin/summary")}
                          >
                            {/* <i className="ti-archive menu-icon"></i> */}
                            <span className="menu-title">
                              <Link className="menu-title" to="/admin/summary">
                                Job Summary
                              </Link>
                            </span>
                            <i className="menu-arrow"></i>
                          </div>
                        </li>
                      )}

                    </ul>
                  </Collapse>
                </li>


              )}
            </>
          )}
          {/* {arrayAdminFeatures?.includes("Job Setup") && (
            <li
              className={
                this.isPathActive("/admin/job-setup") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className="nav-link"
                onClick={() => history.push("/admin/job-setup")}
              >
                <i className="ti-archive menu-icon"></i>
                <span className="menu-title">
                  <Link className="menu-title" to="/admin/job-setup">
                    Job Setup
                  </Link>
                </span>
                <i className="menu-arrow"></i>
              </div>
            </li>
          )}
          {arrayAdminFeatures?.includes("Job Setup") && (
            <li
              className={
                this.isPathActive("/material-records/csv-import") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className="nav-link"
                onClick={() => history.push("/material-records/csv-import")}
              >
                <i className="ti-archive menu-icon"></i>
                <span className="menu-title">
                  <Link className="menu-title" to="/material-records/csv-import">
                    CSV Import
                  </Link>
                </span>
                <i className="menu-arrow"></i>
              </div>
            </li>
          )} */}


          {/* 
          {arrayFeatures?.includes("Rk Material Catalog") && (
            <li
              className={
                this.isPathActive("/rk-material-catalog")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className="nav-link"
                onClick={() => history.push("/rk-material-catalog")}
              >
                <i className="ti-package menu-icon"></i>
                <span className="menu-title">
                  <Link className="menu-title" to="/rk-material-catalog">
                    Rk Material Catalog
                  </Link>
                </span>
                <i className="menu-arrow"></i>
              </div>
            </li>
          )} */}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    const checkPath = this.props.location.pathname.startsWith(path);
    return checkPath;
  }

  componentDidMount() {
    // this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userReducer,
  };
};

export default connect(mapStateToProps)(withRouter(Sidebar));

// export default withRouter(Sidebar);
