import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { userLogoutAction } from "./../store/actions/userActions";
import { Link, withRouter } from "react-router-dom";
// import Widgets from "../widgets/Widgets";
import { removeToken } from "./../services/auth/AuthToken";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.logoNavbar = process.env.REACT_APP_LOGIN_NAVBAR || require("./../assets/images/rk-mechanical-logo.png");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.location?.pathname !== this.props?.location?.pathname) {
      if (this.props?.location?.pathname?.includes("/materialrecords")) {
        document.body.classList.add("sidebar-icon-only");
      } else {
        document.body.classList.remove("sidebar-icon-only");
      }
    }
  }
  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }
  handleLogout = async () => {
    const { history, userLogoutAction } = this.props;
    await userLogoutAction();
    removeToken();

    history.push("/login");
  };
  render() {
    const { history, userData } = this.props;
    const fullName = `${userData?.user?.first_name} ${userData?.user?.last_name}`;
    const getFeatures = process.env.REACT_APP_FEATURES.split(", ");
    return (
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link
            className="navbar-brand brand-logo"
            to="/dashboard"
            style={{
              // display: "flex",
              alignItems: "center",
              justifyContent: "center",
              Width: "100%",
              margin: "0 !important",
            }}
          >
            <img
              src={this.logoNavbar}
              alt="logo"
              style={{
                height: "56px",
                width: "75px",
              }}
            />
            {/* <p
              style={{ textAlign: "center", color: "white", fontWeight: "500" }}
            >
              Connect
            </p> */}
          </Link>
          <Link className="navbar-brand brand-logo-mini" to="/">
            <img
              src={this.logoNavbar}
              alt="logo"
              style={{ width: "48px", height: "48px" }}
            />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch justify-content-end">
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <span className="ti-layout-grid2"></span>
          </button>
          <ul className="navbar-nav mr-lg-2">
            <li className="nav-item nav-search d-none d-lg-block">
              {
                getFeatures.includes('Help') && (<Link to="/contact-us">
                  <span className="btn" style={{
                    fontWeight: '600',
                    backgroundColor: '#282f3a',
                    color: 'white',
                    textDecoration: 'none',
                  }}>Help</span>
                </Link>)
              }

              {/* <div className="input-group">
                <div
                  className="input-group-prepend hover-cursor"
                  id="navbar-search-icon"
                >
                  <span className="input-group-text" id="search">
                    <i className="ti-search"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="navbar-search-input"
                  placeholder="Search now"
                  aria-label="search"
                  aria-describedby="search"
                ></input>
              </div> */}
            </li>
          </ul>
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item mr-2">{fullName}</li>
            {/* <li className="nav-item mr-1">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator">
                  <i className="ti-email"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown">
                  <p className="p-3 mb-0 text-black">Messages</p>
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
            {/* <li className="nav-item">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator">
                  <i className="ti-bell"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                  <p className="p-3 mb-0 text-black">Notifications</p>
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
            <li className="nav-item nav-profile">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator">
                  <img
                    style={{ width: "32px", height: "32px" }}
                    src={require("./../assets/images/profile-blank.png")}
                    alt="profile"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown">
                  <Dropdown.Item
                    className="dropdown-item preview-item"
                    onClick={() => history.push("/settings")}
                  >
                    <div className="d-flex align-items-center">
                      <i className="ti-settings text-primary"></i>
                      <span className="pl-2">Settings</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item preview-item"
                    onClick={this.handleLogout}
                  >
                    <div className="d-flex align-items-center">
                      <i className="ti-power-off text-primary"></i>
                      <span className="pl-2">
                        {/* <Link to="/login" onClick={() => removeToken()}>
                          Logout
                        </Link> */}
                        Logout
                      </span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={this.toggleOffcanvas}
          >
            <span className="ti-layout-grid2"></span>
          </button>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogoutAction: () => dispatch(userLogoutAction()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
